<template>
  <div class="columns is-centered">
    <div class="column is-two-fifths">
      <br>
      <form @submit.prevent="register">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Inscription</p>
          </header>
          <section class="modal-card-body">
            <b-field label="Nom">
              <b-input type="text" v-model="form.name" placeholder="Votre nom" maxlength="50" :has-counter="false"  required>
              </b-input>
            </b-field>
            <b-field label="Pseudo">
              <b-input type="text" v-model="form.nickname" placeholder="Votre pseudo" maxlength="30" :has-counter="false" required>
              </b-input>
            </b-field>
            <b-field label="Adresse mail">
              <b-input type="email" v-model="form.email" placeholder="Votre adresse maill" maxlength="50" :has-counter="false" required>
              </b-input>
            </b-field>
            <b-field label="Mot de passe">
              <b-input type="password" v-model="form.password" placeholder="Votre mot de passe" minlength="8" maxlength="30" :has-counter="false" required>
              </b-input>
            </b-field>
            <b-field label="Confirmation du mot de passe">
              <b-input type="password" v-model="form.confirmPassword" placeholder="De nouveau votre mot de passe" minlength="8" maxlength="30" :has-counter="false" required>
              </b-input>
            </b-field>
            <b-field label="Adresse postale">
              <b-input type="text" v-model="form.address" placeholder="Votre adresse postale" maxlength="60" :has-counter="false" required>
              </b-input>
            </b-field>
            <b-field label="Téléphone">
              <b-input v-model="form.phone" placeholder="Votre numéro de téléphone" maxlength="25" :has-counter="false" required>
              </b-input>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button
              class="is-pulled-right"
              label="S'inscrire"
              native-type="submit"
              type="is-success submit"/>
          </footer>
          <b-progress type="is-info" v-if="sending"></b-progress>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import notification from "@/mixins/notification";

export default {
  name: 'RegisterForm',
  data: () => ({
    user: {},
    form: {
      name: null,
      nickname: null,
      email: null,
      password: null,
      confirmPassword: null,
      address: null,
      phone: null
    },
    sending: false
  }),
  mixins: [notification],
  methods: {
    register() {
      this.sending = true;
      if (this.form.password !== this.form.confirmPassword) {
        this.showError('Les mots de passe doivent être identiques.')
        this.sending = false;
        return;
      }
      this.$store.dispatch('register', this.form)
        .then(() => {
          this.showNotification('Votre compte a été créé.', 'success')
          router.push('/');
        })
        .catch((error) => {
          this.showError('Le compte n\'a pas pu être créé.')
          this.sending = false;
        });
    }
  }
};
</script>

<style scoped>

.modal-card {
  width: auto;
}

</style>
